import { Box, Button, Flex, Heading, Progress, Select, SkeletonCircle, SkeletonText, Switch } from "@chakra-ui/react";
import React, { Component } from "react";
import { withRouter } from "react-router";
import MyTable from "../components/admin/MyTable";
import { withAdminCtx } from "../contexts/AdminContext";
import { withModalsCtx } from "../contexts/ModalsContext";
import { withWareCtx } from "../contexts/WarehouseContext";
import Utils from "../Utils";
import { MRetentionCohortGraph } from "./DReportGrafici";
import { START_YEAR } from "./DReportsKPI";
import { arraySum, filterClientiNuovi, flattenClienti, flattenClientiGrouped, groupBy, groupByArray } from "./KPIUtils";
var moment = require("moment");

class DReportLVG extends Component {
  state = {
    one_month: true,
    monthDataWoo: [],
    retentionCohortWoo: [],
    monthDataAmazon: [],
    retentionCohortAmazon: [],
    tab: "customers",
    cohortab: "clienti",
    commerce: "woo",
    loadWoo: false,
    loadAmazon: false,
    retentionCohort: [],
    selectedYear: 2022,
    selectedMonth: "01",
  };

  renderSkeletonText(v, t, hideSkeleton = false) {
    return (
      <>
        <Box my={5}>
          <Heading size="sm" w="fit-content" textAlign={"center"} m={"auto"}>
            {t}
          </Heading>

          <Progress value={v} />
        </Box>
        {!hideSkeleton && (
          <Box padding="6" boxShadow="lg" bg="white">
            <SkeletonCircle size="10" />
            <SkeletonText mt="4" noOfLines={4} spacing="4" />
          </Box>
        )}
      </>
    );
  }

  componentDidMount() {
    const { clientiTotaliAggregate, clientiTotaliLVG } = this.props;
    const ordini = flattenClienti(clientiTotaliLVG);

    console.log(
      "vv clientiTotaliLVG",
      clientiTotaliLVG?.filter((c) => c.num_ordini > 1),
      clientiTotaliLVG,
      ordini,
    );

    this.setState({ loadWoo: true });
    const { monthData: montDataWaoo, retentionCohort: retentionCohortWoo } = this.loadData(
      this.state.monthDataWoo,
      ordini,
      clientiTotaliLVG,
      true,
    );
    this.setState({ loadAmazon: true });
    const { monthData: monthDataAmazon, retentionCohort: retentionCohortAmazon } = this.loadData(
      this.state.monthDataAmazon,
      ordini,
      clientiTotaliLVG,
      false,
    );

    console.log("ww retentionCohortWoo", retentionCohortWoo);

    this.setState({
      yearLoading: null,
      loadWoo: false,
      loadAmazon: false,
      monthDataWoo: montDataWaoo,
      retentionCohortWoo: retentionCohortWoo,
      monthDataAmazon: monthDataAmazon,
      retentionCohortAmazon: retentionCohortAmazon,
    });
  }

  loadData(monthData, ordini, clientiTotaliLVG, isWoo) {
    const startYear = START_YEAR;
    const currentYear = moment().year();
    const months = [];
    for (let i = startYear; i <= currentYear; i++)
      for (let j = 1; j <= 12; j++) {
        months.push(
          j.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) +
            "-" +
            i,
        );
      }

    console.log("vv months", months);

    let cumulative = 0;
    let cumulativeLost = 0;
    let index = 0;
    let closing = 0;
    let clientiNuoviCumulative = 0;

    let ordiniCumulative = 0;
    let revenuesCumulative = 0;
    let frequenzaAcquisto = 0;
    let lifeTimeValue = 0;
    const retentionCohort = [];

    months.forEach((m) => {
      // if (index >0) return;
      // index++;

      this.setState({ yearLoading: m });
      const _ordini = ordini.filter(
        (o) =>
          moment(o.dateFormatted, "DD MM YYYY").format("MM-YYYY") === m &&
          (isWoo ? o.piattaforma === "WOO" : o.piattaforma != "WOO"),
      );

      const clientiMese = groupByArray(_ordini, "codCliente");

      cumulative += clientiMese.length;

      const month = moment(m, "MM-YYYY").format("MMMM YY");
      if (!retentionCohort[month]) retentionCohort[month] = {};
      retentionCohort[month].clientiUnici = flattenClientiGrouped(clientiMese);

      let starting = closing > 0 ? closing : 0;
      let nuovi = 0;
      let returning = 0;
      let riattivati = 0;
      let ordiniMeseClientiNuovi = 0;
      let ordiniMeseClientiRicorrenti = 0;

      let revenuesClientiNuovi = 0;
      let revenuesClientiRicorrenti = 0;

      let prodottiMeseClientiNuovi = 0;
      let prodottiMeseClientiRicorrenti = 0;

      let lost = clientiTotaliLVG
        ?.filter((c) => (isWoo ? c.piattaforma === "WOO" : c.piattaforma !== "WOO"))
        .filter((c) => moment(m, "MM-YYYY").diff(moment(c.lastPurchaseDate, "DD-MM-YYYY"), "months") === 12).length;
      // console.log(
      //   "WW clienti lost",
      //   m,
      //   clientiTotaliLVG?.filter(
      //     c =>
      //       moment(m, "MM-YYYY").diff(
      //         moment(c.lastPurchaseDate, "DD-MM-YYYY"),
      //         "months"
      //       ) === 12
      //   )
      // );

      clientiMese.forEach((c) => {
        const cliente = c.group[0];
        const ordiniMesiPrecedenti = cliente.ordiniReali.filter(
          (o) =>
            (isWoo ? o.piattaforma === "WOO" : o.piattaforma != "WOO") &&
            moment(o.dateFormatted, "DD-MM-YYYY").isBefore(moment(m, "MM-YYYY")),
        );
        const ordiniClienteMese = cliente.ordiniReali.filter(
          (o) =>
            (isWoo ? o.piattaforma === "WOO" : o.piattaforma != "WOO") &&
            moment(o.dateFormatted, "DD-MM-YYYY").format("MM-YYYY") === m,
        );
        // console.log("WW ordiniClienteMese", ordiniClienteMese);

        if (ordiniMesiPrecedenti.length === 0) {
          nuovi++;
          ordiniMeseClientiNuovi += ordiniClienteMese.length;
          revenuesClientiNuovi += arraySum(ordiniClienteMese, "fatturato");
          prodottiMeseClientiNuovi += arraySum(ordiniClienteMese, "qtyProdotti");
        } else {
          returning++;
          ordiniMeseClientiRicorrenti += ordiniClienteMese.length;
          revenuesClientiRicorrenti += arraySum(ordiniClienteMese, "fatturato");
          prodottiMeseClientiRicorrenti += arraySum(ordiniClienteMese, "qtyProdotti");

          // console.log("VV cliente missing", ordiniMesiPrecedenti, m);
        }

        if (
          !!cliente.previousPurchaseDate &&
          moment(m, "MM-YYYY").diff(moment(cliente.previousPurchaseDate, "DD-MM-YYYY"), "months") > 12
        ) {
          riattivati++;
        }

        // console.log(
        //   "vv cliente cc",
        //   cliente,
        //   clientiTotaliLVG[cliente.codCliente]
        // );
      });

      closing = starting + nuovi + riattivati - lost;
      cumulativeLost += lost;
      const ordiniMese = ordiniMeseClientiNuovi + ordiniMeseClientiRicorrenti;
      ordiniCumulative += ordiniMese;
      const revenuesMese = revenuesClientiNuovi + revenuesClientiRicorrenti;
      const carrelloMedioMese = revenuesMese / ordiniMese;

      clientiNuoviCumulative += nuovi;

      frequenzaAcquisto = (ordiniCumulative / clientiNuoviCumulative).toFixed(2);
      lifeTimeValue = carrelloMedioMese * frequenzaAcquisto;

      revenuesCumulative += revenuesMese;
      if (ordiniMese > 0) {
        monthData.push({
          date: m,
          // mese: moment(m, "MM-YYYY").format("MMMM YYYY"),
          mese: moment(m, "MM-YYYY").format("YYYY-MM"),
          ordini: _ordini,
          clientiMese: clientiMese,
          clientiMeseTot: clientiMese.length,
          cumulative: cumulative,
          cumulativeLost: cumulativeLost,
          reactivationPerc: riattivati === 0 ? "0%" : (riattivati / cumulativeLost).toFixed(2) + "%",
          nuovi: nuovi,
          returning: returning,
          riattivati: riattivati,
          lost: lost,
          closing: closing,
          starting: starting,
          //ordini
          clientiNuoviCumulative: clientiNuoviCumulative,
          ordiniMese: ordiniMese,
          ordiniCumulative: ordiniCumulative,
          ordiniMeseClientiNuovi: ordiniMeseClientiNuovi,
          ordiniMeseClientiRicorrenti: ordiniMeseClientiRicorrenti,
          ordiniRetention: ((ordiniMeseClientiRicorrenti / ordiniMese) * 100).toFixed(2) + "%" || "0%",
          frequenzaAcquisto: frequenzaAcquisto,
          lifeTimeValue: Utils.stringToCurrency(lifeTimeValue),
          //revenues
          revenuesMese: Utils.stringToCurrency(revenuesMese),
          revenuesClientiNuovi: Utils.stringToCurrency(revenuesClientiNuovi),
          revenuesClientiRicorrenti: Utils.stringToCurrency(revenuesClientiRicorrenti),
          revenuesCumulative: Utils.stringToCurrency(revenuesCumulative),
          carrelloMedioMese: Utils.stringToCurrency(carrelloMedioMese),
          carrelloMedioClientiNuovi: Utils.stringToCurrency(revenuesClientiNuovi / ordiniMeseClientiNuovi),
          carrelloMedioClientiRicorrenti: Utils.stringToCurrency(
            revenuesClientiRicorrenti / ordiniMeseClientiRicorrenti,
          ),
          //prodotti
          prodottiMese: prodottiMeseClientiNuovi + prodottiMeseClientiRicorrenti,
          prodottiMeseClientiNuovi: prodottiMeseClientiNuovi,
          prodottiMeseClientiRicorrenti: prodottiMeseClientiRicorrenti,
          pezziOrdineMese: ((prodottiMeseClientiNuovi + prodottiMeseClientiRicorrenti) / ordiniMese).toFixed(2),
          pezziOrdineClientiNuovi: (prodottiMeseClientiNuovi / ordiniMeseClientiNuovi).toFixed(2),
          pezziOrdineClientiRicorrenti: (prodottiMeseClientiRicorrenti / ordiniMeseClientiRicorrenti).toFixed(2),
          //cohorti
        });
      }
    });

    console.log("vv monthData", monthData);
    return {
      monthData: monthData,
      retentionCohort: retentionCohort,
    };
  }

  render() {
    const { clientiTotaliAggregate, clientiTotaliLVG } = this.props;
    const columns = this.getColumns();

    const data = this.state.commerce === "woo" ? this.state.monthDataWoo : this.state.monthDataAmazon;

    console.log("AA this.state.monthDataWoo", this.state.monthDataWoo, this.state.commerce, data);
    const renderButton = (tab) => {
      return (
        <Button
          colorScheme="green"
          variant={this.state.tab === tab ? "solid" : "outline"}
          onClick={() => this.setState({ tab })}
        >
          {tab.toUpperCase()}
        </Button>
      );
    };
    const renderCohortButton = (cohortab) => {
      return (
        <Button
          colorScheme={"orange"}
          variant={this.state.cohortab === cohortab ? "solid" : "outline"}
          onClick={() => this.setState({ cohortab })}
        >
          {cohortab.toUpperCase()}
        </Button>
      );
    };

    const startDate = moment(this.state.selectedYear + "-" + this.state.selectedMonth, "YYYY-MM").startOf("month");

    const endDate = moment(this.state.selectedYear + "-" + this.state.selectedMonth, "YYYY-MM")
      .add(12, "months")
      .endOf("month");

    console.log("WW startDate", startDate, endDate);
    const cohort = {
      retentionCohort: this.state.commerce === "woo" ? this.state.retentionCohortWoo : this.state.retentionCohortAmazon,

      one_month: true,
      startDate: startDate,
      endDate: endDate,
      selectedYear: this.state.selectedYear,
      filterFirstMonth: (cc, m) => {
        return filterClientiNuovi(cc, m, this.state.commerce);
      },
    };

    console.log("ww change yea cohort", cohort, this.state.retentionCohortWoo, this.state.retentionCohortAmazon);

    const getOrdiniMese = (d, m, one_month) => {
      const m2 = moment(m, "MMMM YY").add(1, "M").format("MMMM YY");
      const m3 = moment(m2, "MMMM YY").add(1, "M").format("MMMM YY");
      console.log("OO ordini mese", m, m2, m3);
      return d.ordiniReali.filter(
        (o) =>
          (this.state.commerce === "woo" ? o.piattaforma === "WOO" : o.piattaforma != "WOO") &&
          (one_month
            ? moment(o.dateFormatted, "DD-MM-YYYY").format("MMMM YY") === m
            : moment(o.dateFormatted, "DD-MM-YYYY").format("MMMM YY") === m ||
              moment(o.dateFormatted, "DD-MM-YYYY").format("MMMM YY") === m2 ||
              moment(o.dateFormatted, "DD-MM-YYYY").format("MMMM YY") === m3),
      );
    };

    return (
      <>
        {this.state.yearLoading &&
          this.renderSkeletonText(80, "Calcolando clienti mese/anno " + this.state.yearLoading, true)}
        {this.state.loadWoo && this.renderSkeletonText(80, "Calcolando clienti e-commerce", true)}
        {this.state.loadAmazon && this.renderSkeletonText(80, "Calcolando clienti Amazon", true)}
        <Flex justify={"space-between"}>
          <Flex gap={2}>
            <Select
              value={this.state.selectedYear}
              w="100px"
              onChange={(v) => {
                console.log("ww change year", v.target.value);
                this.setState({ selectedYear: v.target.value });
              }}
            >
              <option value="2025">2025</option>
              <option value="2024">2024</option>
              <option value="2023">2023</option>
              <option value="2022">2022</option>
              <option value="2021">2021</option>
              <option value="2020">2020</option>
              <option value="2019">2019</option>
            </Select>
            {renderButton("customers")}
            {renderButton("ordini")}
            {renderButton("revenues")}
            {renderButton("prodotti")}
            {renderButton("cohorti")}
          </Flex>
          <Flex gap={2}>
            <Button
              colorScheme={"red"}
              variant={this.state.commerce === "woo" ? "solid" : "outline"}
              onClick={() => this.setState({ commerce: "woo" })}
            >
              E-COMMERCE
            </Button>
            <Button
              colorScheme={"red"}
              variant={this.state.commerce === "amazon" ? "solid" : "outline"}
              onClick={() => this.setState({ commerce: "amazon" })}
            >
              AMAZON
            </Button>
          </Flex>
        </Flex>
        {/* <Heading size="lg">Customers {clientiTotaliLVG?.length}</Heading> */}

        {this.state.tab === "cohorti" ? (
          <>
            <Flex gap={2} mt={5} align="center" bg="gray.500" p={2}>
              {renderCohortButton("clienti")}
              {renderCohortButton("ordini")}
              {renderCohortButton("prodotti")}
              {renderCohortButton("fatturato")}

              {this.renderMonth()}

              <Flex gap={1}>
                <Heading size={"sm"} color="white">
                  3 mesi
                </Heading>
                <Switch
                  variant={this.state.one_month ? "otulined" : "undefined"}
                  onChange={() => this.setState({ one_month: !this.state.one_month })}
                  isChecked={!this.state.one_month}
                />
              </Flex>
            </Flex>

            {this.state.cohortab === "clienti" && (
              <>
                <MRetentionCohortGraph
                  {...cohort}
                  // percent={false}

                  title={"CLIENTI"}
                  one_month={this.state.one_month}
                />
                {/* <MRetentionCohortGraph
                  {...cohort}
                  title={"CLIENTI"}
                  one_month={true}
                /> */}
              </>
            )}
            {this.state.cohortab === "ordini" && (
              <>
                <MRetentionCohortGraph
                  {...cohort}
                  title={"ORDINI"}
                  one_month={this.state.one_month}
                  calculateOnField={(d, m, one_month) => {
                    console.log(
                      "ww data",
                      m,
                      d,
                      d.reduce((a, b) => +a + +getOrdiniMese(b, m, one_month).length, 0),
                    );
                    return d.reduce((a, b) => +a + +getOrdiniMese(b, m, one_month).length, 0);
                  }}
                />
              </>
            )}
            {this.state.cohortab === "prodotti" && (
              <>
                <MRetentionCohortGraph
                  {...cohort}
                  title={"PRODOTTI"}
                  one_month={this.state.one_month}
                  calculateOnField={(d, m, one_month) => {
                    return d.reduce((a, b) => +a + +arraySum(getOrdiniMese(b, m, one_month), "qtyProdotti"), 0);
                  }}
                />
              </>
            )}
            {this.state.cohortab === "fatturato" && (
              <>
                <MRetentionCohortGraph
                  {...cohort}
                  percent={false}
                  title={"FATTURATO"}
                  one_month={this.state.one_month}
                  calculateOnField={(d, m, one_month) => {
                    return d
                      .reduce((a, b) => +a + +arraySum(getOrdiniMese(b, m, one_month), "fatturato"), 0)
                      .toFixed(2);
                  }}
                />
              </>
            )}
          </>
        ) : (
          <MyTable
            data={data.filter((d) => {
              const startDate = moment(this.state.selectedYear, "YYYY").startOf("year");
              const endDate = moment(this.state.selectedYear, "YYYY").endOf("year");
              return (
                moment(d.mese, "yyyy-mm").isSameOrBefore(endDate) && moment(d.mese, "yyyy-mm").isSameOrAfter(startDate)
              );
            })}
            columns={columns}
            {...this.props}
            hideButtons
          />
        )}
      </>
    );
  }

  getColumns() {
    let columns = [];
    if (this.state.tab === "customers") {
      columns = [
        {
          Header: "Mese",
          accessor: "mese",
        },
        {
          Header: "Cumulative",
          accessor: "cumulative",
        },
        {
          Header: "Clienti Mese",
          accessor: "clientiMeseTot",
        },
        {
          Header: "Starting",
          accessor: "starting",
        },
        {
          Header: "Nuovi",
          accessor: "nuovi",
        },
        {
          Header: "Returning",
          accessor: "returning",
        },
        {
          Header: "Riattivati",
          accessor: "riattivati",
        },
        {
          Header: "Lost",
          accessor: "lost",
        },
        {
          Header: "Closing",
          accessor: "closing",
        },
        {
          Header: "Cumulative Lost",
          accessor: "cumulativeLost",
        },
        {
          Header: "Tasso riattivazione",
          accessor: "reactivationPerc",
        },
      ];
    } else if (this.state.tab === "ordini") {
      columns = [
        {
          Header: "Mese",
          accessor: "mese",
        },
        {
          Header: "Cumulative",
          accessor: "ordiniCumulative",
        },
        {
          Header: "Ordini Mese",
          accessor: "ordiniMese",
        },

        {
          Header: "Nuovi",
          accessor: "ordiniMeseClientiNuovi",
        },
        {
          Header: "Returning",
          accessor: "ordiniMeseClientiRicorrenti",
        },

        {
          Header: "Retention",
          accessor: "ordiniRetention",
        },
        {
          Header: "clientiNuoviCumulative",
          accessor: "clientiNuoviCumulative",
        },
        {
          Header: "Freq Acquisto",
          accessor: "frequenzaAcquisto",
        },
        {
          Header: "LTV",
          accessor: "lifeTimeValue",
        },
      ];
    } else if (this.state.tab === "revenues") {
      columns = [
        {
          Header: "Mese",
          accessor: "mese",
        },
        {
          Header: "Revenues Cumulative",
          accessor: "revenuesCumulative",
        },
        {
          Header: "Revenues Mese",
          accessor: "revenuesMese",
        },
        {
          Header: "Revenues Clienti Nuovi",
          accessor: "revenuesClientiNuovi",
        },
        {
          Header: "Revenues Clienti Ricorrenti",
          accessor: "revenuesClientiRicorrenti",
        },
        {
          Header: "AVG Order Tot",
          accessor: "carrelloMedioMese",
        },
        {
          Header: "AVG Order Clienti Nuovi",
          accessor: "carrelloMedioClientiNuovi",
        },
        {
          Header: "AVG Order Clienti Ricorrenti",
          accessor: "carrelloMedioClientiRicorrenti",
        },
      ];
    } else if (this.state.tab === "prodotti") {
      columns = [
        {
          Header: "Mese",
          accessor: "mese",
        },
        {
          Header: "Pezzi venduti",
          accessor: "prodottiMese",
        },
        {
          Header: "Pezzi Venduti Clienti Nuovi",
          accessor: "prodottiMeseClientiNuovi",
        },
        {
          Header: "Pezzi Venduti Clienti Ricorrenti",
          accessor: "prodottiMeseClientiRicorrenti",
        },
        {
          Header: "Pezzi per ordine",
          accessor: "pezziOrdineMese",
        },
        {
          Header: "Pezzi per ordine Clienti Nuovi",
          accessor: "pezziOrdineClientiNuovi",
        },
        {
          Header: "Pezzi per ordine Clienti Ricorrenti",
          accessor: "pezziOrdineClientiRicorrenti",
        },
      ];
    }
    return columns;
  }

  renderMonth() {
    return (
      <Select
        value={this.state.selectedMonth}
        w="150px"
        onChange={(v) => {
          console.log("ww change month", v.target.value);
          this.setState({ selectedMonth: v.target.value });
        }}
      >
        <option value="01">Gennaio</option>
        <option value="02">Febbraio</option>
        <option value="03">Marzo</option>
        <option value="04">Aprile</option>
        <option value="05">Maggio</option>
        <option value="06">Giugno</option>
        <option value="07">Luglio</option>
        <option value="08">Agosto</option>
        <option value="09">Settembre</option>
        <option value="10">Ottobre</option>
        <option value="11">Novembre</option>
        <option value="12">Dicembre</option>
      </Select>
    );
  }
}

export default withModalsCtx(withRouter(withAdminCtx(withWareCtx(DReportLVG))));
